<template>
  <div>
    <img
      src="@/assets/bits/1.png"
      class="absolute max-w-[260px] top-0 right-0"
    />
    <img
      src="@/assets/bits/2.png"
      class="absolute max-w-[100px] top-[20vh] left-0"
    />

    <img
      src="@/assets/intro-main.gif"
      class="max-w-[400px] w-full height-auto mx-auto block"
    />

    <div class="p-6 space-y-4 max-w-[550px] mx-auto relative z-10 text-center">
      <p class="text-textgrey text-base">
        The app that helps you save for your first home, Nude, is now officially
        available on Android and iOS!
      </p>

      <p class="text-textgrey text-base">
        We’ve got Lifetime ISAs, a market-leading interest rate and money-saving
        features to help you buy your first home sooner 🏠
      </p>

      <p class="text-textgrey text-base">
        To celebrate our big Android debut, we’re giving away 💰£10,000💰 to
        first-time buyers!
      </p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);
export default {
  name: "EnterIntro",
  mounted() {
    this.$confetti.update({
      particles: [
        {
          type: "heart",
        },
        {
          type: "circle",
        },
      ],
      defaultColors: ["red", "pink", "#ba0000"],
    });
  },
  methods: {
    start(event) {
      event.preventDefault();
      this.$confetti.start();
    },
    stop() {
      console.log("Stop?");
      this.$confetti.stop();
    },
  },
};
</script>

<style scoped>
.bit-1 {
  right: -150px;
  top: -184px;
  width: 500px;
}
.bit-desktop-1 {
  right: -150px;
  top: -184px;
  width: 500px;
}
.bit-2 {
  right: -19%;
  top: 13%;
  transform: rotate(259deg);
  width: 150px;
}

.bit-3 {
  left: -72px;
  margin: -35px 0 0 0;
  transform: scaleX(-1) rotate(176deg);
  width: 189px;
}

.bit-desktop-2 {
  transform: rotate(310deg);
  width: 300px;
  left: -93px;
  margin-top: -78px;
}

.bit-desktop-3 {
  right: 120px;
  top: 300px;
  transform: rotate(291deg);
  width: 189px;
}

.bit-desktop-5 {
  left: 100px;
  margin: -35px 0 0 0;
  width: 300px;
}
</style>
