<template>
  <div class="bg-green">
    <img
      src="@/assets/tears/white-desktop.png"
      class="hidden md:block w-full"
    />
    <img src="@/assets/tears/white-mobile.png" class="md:hidden w-full -mb-4" />

    <div class="bg-eggshell">
      <div
        class="max-w-xl lg:max-w-4xl mx-auto  divide-y divide-green divide px-6 py-6 lg:flex lg:divide-none lg:space-x-3 text-center"
      >
        <div class="max-w-[550px] mx-auto space-y-4">
          <div class="relative">
            <img
              src="@/assets/last-winner-header-bg.png"
              class="p-6 max-w-[300px] mx-auto"
            />
            <h3
              class="text-green font-header text-5xl leading-[0.8]  absolute inset-0 flex items-center justify-center"
            >
              Meet the winner of our last big giveaway
            </h3>
          </div>
          <p class="text-green text-base ">
            Ever wondered what it’s like to get a *legit* phone call telling you
            that you’ve won £10,000 towards your first home? ☎️🏡
          </p>

          <img src="@/assets/last-winner-graphic.png" class="py-2" />

          <p class="text-green text-base ">
            That’s what happened to Aleksandra, the winner of our previous £10k
            giveaway. She’s putting it towards buying her first home on the
            outskirts of London.
          </p>

          <p class="text-green text-base ">
            “Winning was honestly such a big shock. I was at a family friend’s
            house and the first thing I did when I walked back into the room was
            announce that I had just won Nude’s £10,000 giveaway… no one could
            believe it, not even me!”
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastWinner",
};
</script>
