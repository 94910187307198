<template>
  <div
    class="cookies p-4 fixed bottom-0 left-0 right-0 bg-green z-10"
    :class="{ hidden: cookiesAccepted }"
  >
    <div
      class="mx-auto max-w-xl lg:max-w-4xl flex justify-center space-x-4 items-center "
    >
      <p class="flex-1 text-xs lg:text-base text-eggshell">
        You accept the use of cookies by accepting or dismissing this banner, by
        clicking a link or button or by continuing to browse otherwise.
        <a
          href="https://www.getnude.com/policy/cookie"
          class="underline"
          target="_blank"
          >View our cookie policy</a
        >.
      </p>
      <button
        v-on:click="acceptCookies"
        class="bg-eggshell p-1 px-3 font-bold text-textgrey rounded-full text-base"
      >
        Accept
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookies",
  components: {},
  data: function() {
    return {
      cookiesAccepted: false,
    };
  },
  mounted() {
    this.cookiesAccepted = localStorage.getItem("cookiesAccepted");
  },
  methods: {
    acceptCookies() {
      this.cookiesAccepted = true;
      localStorage.setItem("cookiesAccepted", true);
    },
  },
};
</script>

<style></style>
