<template>
  <div class="relative overflow-hidden">
    <Header />
    <main>
      <div class=" mx-auto max-w-[540px]">
        <EnterIntro />
      </div>
      <img
        src="@/assets/tears/green-desktop.png"
        class="hidden md:block w-full"
      />
      <img
        src="@/assets/tears/green-mobile.png"
        class="md:hidden w-full -mb-10"
      />

      <div class="bg-green py-10">
        <div class="mx-auto max-w-[540px]">
          <a
            class="e-widget no-button generic-loader"
            href="https://gleam.io/lLppj/nude-octdec-2022-win-10000-towards-your-first-home"
            rel="nofollow"
            >Nude's £10,000 Deposit Giveaway</a
          >
        </div>
      </div>
      <TeamVoting />
      <LastWinner />
      <Upsell />

      <Footer />
    </main>

    <Cookies />
  </div>
</template>

<script>
import Header from "./components/Header";
import EnterIntro from "./components/EnterIntro";
import TeamVoting from "./components/TeamVoting";
import Upsell from "./components/Upsell";
import LastWinner from "./components/LastWinner";
import Footer from "./components/Footer";
import Cookies from "./components/Cookies";

export default {
  name: "Enter",
  components: {
    Header,
    EnterIntro,
    LastWinner,
    TeamVoting,
    Upsell,
    Footer,
    Cookies,
  },
  data: function() {
    return {
      APIData: [],
    };
  },
  mounted() {
    console.log("Mounted");
    this.$loadScript("https://widget.gleamjs.io/e.js")
      .then(() => {
        // Script is loaded, do something
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
  destroyed() {
    console.log("Destroyed");
    this.$unloadScript("https://widget.gleamjs.io/e.js")
      .then(() => {
        // Script is loaded, do something
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
  methods: {},
};
</script>
<style scoped>
.bit-desktop-2 {
  transform: rotate(310deg);
  width: 300px;
  left: -93px;
  margin-top: -78px;
}

.bit-3 {
  left: -72px;
  margin: -35px 0 0 0;
  transform: scaleX(-1) rotate(176deg);
  width: 189px;
}

.bit-4 {
  right: -29%;
  margin: -109px 0 0 0;
  width: 200px;
  height: auto;
}
.bit-desktop-4 {
  right: -150px;
  margin: -159px 0 0 0;
  width: 300px;
  height: auto;
}
</style>
