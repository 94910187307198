<template>
  <div class=" bg-eggshell pb-10 relative">
    <img
      src="@/assets/bits/3.png"
      class="absolute max-w-[140px] top-[0] left-0"
    />
    <div
      class="max-w-xl relative z-20 lg:max-w-4xl mx-auto  divide-y divide-green divide px-6 py-6 lg:flex lg:divide-none lg:space-x-3 text-center"
    >
      <div class="max-w-[550px] mx-auto space-y-4">
        <h3 class="text-green font-header text-5xl leading-[0.8]">
          What’s Nude?
        </h3>
        <p class="text-green text-base ">
          Nude is the app that helps you save for your first home. Learn more
          about how our Lifetime ISAs and money-saving features can help you get
          those keys sooner.
        </p>

        <p class="text-green text-base ">
          Whether you wanna start building your first home deposit on Android or
          iPhone, we’ve got an app for both.
        </p>

        <a
          href="https://89ld0.app.link/giveawayOct2022" target="_blank"
          class="p-3 block rounded-full bg-red font-bold text-white w-full  text-base text-center max-w-[225px] mx-auto "
        >
          Download Our App
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastWinner",
};
</script>
